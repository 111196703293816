<template>
    <div>
        <NavigationTabs
                :children-routes=" childrenRoutes"
                :newNotifications="newNotifications.length"
        />
        <router-view/>
    </div>
</template>

<script>
    import notificationsChildren from "@/router/notifications/children"
    import notificationsAdminChildren from "@/router/notificationsAdmin/children"
    import NavigationTabs from "@/components/common/NavigationTabs"
    import {mapGetters} from "vuex"


    export default {
        name: "Profile",
        components: {
            NavigationTabs
        },
        computed: {
            ...mapGetters('auth', ['authorities']),
            ...mapGetters('impersonalization', ['mindControlledUser']),
            ...mapGetters('notifications', ['newNotifications']),
            childrenRoutes() {
                if(this.authorities.includes('ROLE_ADMIN') && !this.mindControlledUser){
                    return notificationsAdminChildren
                }else{
                    return notificationsChildren
                }
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
