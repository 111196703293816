import { render, staticRenderFns } from "./NavigationTabs.vue?vue&type=template&id=12ab2f97&scoped=true"
import script from "./NavigationTabs.vue?vue&type=script&lang=js"
export * from "./NavigationTabs.vue?vue&type=script&lang=js"
import style0 from "./NavigationTabs.vue?vue&type=style&index=0&id=12ab2f97&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12ab2f97",
  null
  
)

export default component.exports