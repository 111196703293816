<template>
    <div class="tabs">
        <router-link
                v-for="route in childrenRoutes"
                :key="route.path"
                :to="{name: route.name}"
                tag="div"
                active-class="active"
                class="tab-btn"
                exact
        >
            {{route.display}} <span v-if="route.display === 'Новые уведомления' && newNotifications > 0">{{ newNotifications }}</span>
        </router-link>
    </div>
</template>

<script>
    export default {
        name: "NavigationTabs",
        props: ['childrenRoutes', 'newNotifications']
    }
</script>

<style lang="less" scoped>
    @import '../../assets/less/variables.less';

    .tabs {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 16px;
        border-bottom: 1px solid #eeeeee;

    }

    .tab-btn {
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0 16px;
        height: 47px;
        font-weight: 500;
        font-size: 13px;
        letter-spacing: 0.25px;
        text-align: center;
        color: @text-color-light;
        transition: @trans;
        cursor: pointer;

        span{
            background-color: #DC3838;
            color: #FFFFFF;
            border-radius: 50%;
            margin-left: 10px;
            width: 23px;
            height: 23px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: -1px;
            z-index: 2;
            height: 2px;
            background-color: @text-color-light;
            opacity: 0;
        }

        &.active {
            color: @text-color;

            &::after {
                opacity: 1;
            }
        }

        &:hover {
            color: @text-color;
        }
    }
</style>